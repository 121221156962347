import { v4 as uuidv4 } from 'uuid';

import { OPERATING_SYSTEMS } from '~/config';
import { getLog, setLog } from '~/controllers/logs';

export const toggleElement = (element: Element, state: boolean, fade: boolean): void => {
  const toggleClassIn = fade ? 'is-fade-in' : 'is-visible';
  const toggleClassOut = fade ? 'is-fade-out' : 'is-hidden';

  element.classList.remove('is-hidden');
  element.classList.toggle(toggleClassIn, state);
  element.classList.toggle(toggleClassOut, !state);
};

export const getOS = (): string => {
  if (!window) {
    return;
  }

  const { platform } = window.navigator;

  if (platform.includes('Win')) {
    return OPERATING_SYSTEMS.WINDOWS;
  } else if (platform.includes('Mac')) {
    return OPERATING_SYSTEMS.MAC;
  } else if (platform.includes('Linux')) {
    return OPERATING_SYSTEMS.CHROME;
  } else {
    return null;
  }
};

export const getUUID = (): string => {
  const localDeviceID = getLog('deviceID');

  if (!localDeviceID) {
    const deviceID = `${uuidv4().slice(0, -4)}_PWA`;
    setLog('deviceID', deviceID);
    return deviceID;
  } else {
    return localDeviceID;
  }
};

export const clearCache = (): void => {
  caches.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      if (cacheName.includes('runtime')) {
        caches.delete(cacheName);
      }
    });
  });
};
