import { toggleElement } from '~/utils';
import { BRANDS } from '~/config';
import { getLog } from './logs';
import { brandColors } from '../styles/colors';
import { VERSION } from '../config';

const loaderElement = document.querySelector('.dsp-loader') as HTMLElement;
const loaderImage: HTMLImageElement = document.querySelector('.dsp-loader__image');
const messageElement = document.querySelector('.dsp-message');
const messageText = document.querySelector('.dsp-message__text');
const versionSpan = document.querySelector('.dsp-version');

export const showLoader = (brand?: string): void => {
  if (!loaderElement) {
    return;
  }

  if (versionSpan) {
    versionSpan.innerHTML = 'v' + VERSION;
  }

  const clientName = brand || getLog('clientName');

  switch (clientName) {
    case BRANDS.AUDI:
      loaderImage.src = 'images/audi-logo.svg';
      break;
    case BRANDS.CUPRA:
      loaderImage.src = 'images/cupra-logo.png';
      break;
    case BRANDS.VOLKSWAGEN:
      loaderElement.style.backgroundColor = brandColors.volkswagen.background;
      loaderImage.src = 'images/volkswagen-logo.svg';
      break;
    default:
      break;
  }
  toggleElement(loaderElement, true, true);
};

export const displayLoaderMessage = (inputMessage = ''): void => {
  if (!messageElement) {
    return;
  }

  toggleElement(messageElement, true, true);
  messageText.innerHTML = inputMessage;
};

export const hideLoader = (): void => {
  if (!loaderElement) {
    return;
  }

  toggleElement(loaderElement, false, true);
};
