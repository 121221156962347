import { IPlaylistItemSanitized, IPlaylistSanitized } from '~/interfaces/playlists';
import { displayLoaderMessage, hideLoader } from '~/controllers/loader';
import { showSplash } from '~/controllers/splash';
import { setLog, setStorageLog } from '~/controllers/logs';

import { PLAYLIST_DEFAULTS } from '~/config';
import { getFallbackPlaylist, getPlaylist } from '~/services/playlists';
import { clearCache, toggleElement } from '~/utils';
import { log } from './logs';
import { isEmpty, equals } from 'ramda';
import { storeClientName } from '../services/registration';

declare global {
  interface Window {
    _paq: any;
  }
}

export interface IPlayer {
  init: () => Promise<void>;
  checkForPlaylistUpdates: () => void;
  renderAsset: (overrideAsset?: IPlaylistItemSanitized) => void;
  renderImage: (item: IPlaylistItemSanitized) => void;
  renderVideo: (item: IPlaylistItemSanitized) => void;
  resetPlayer: () => void;
  pause: () => void;
  play: () => void;
}

export default class Player implements IPlayer {
  imageElement: HTMLImageElement;
  videoElement: HTMLVideoElement;
  iframeElement: HTMLIFrameElement | null;
  playerElement: HTMLDivElement;
  videoSource: HTMLElement;
  playlist: IPlaylistSanitized;
  overrideAsset: IPlaylistItemSanitized;
  updatedPlaylist: IPlaylistSanitized;
  currentIndex: number;
  playerStatus: string;
  playerTimesPlayed: number;
  _paq: any;
  imageTimeout: NodeJS.Timeout;
  playlistUpdateInterval: NodeJS.Timeout;

  constructor() {
    this.imageElement = document.querySelector('.dsp-player__image');
    this.videoElement = document.querySelector('.dsp-player__video');
    this.iframeElement = null;
    this.playerElement = document.querySelector('.dsp-player');
    this.videoSource = document.querySelector('.dsp-player__video source');
    this.currentIndex = 0;
    this.playlist = null;
    this.overrideAsset = null;
    this.imageTimeout = null;
    this.playerTimesPlayed = 0;
    this._paq = window._paq = window._paq || [];
    this.playerStatus = 'inactive';

    setLog('playerStatus', this.playerStatus);
    setLog('playerTimesPlayed', this.playerTimesPlayed);
  }

  init = async (): Promise<void> => {
    if (!this.videoElement || !this.imageElement) {
      return;
    }

    this.playlist = await getPlaylist();

    storeClientName(this.playlist.brand);

    if (isEmpty(this.playlist.items)) {
      log('PLAYLIST IS EMPTY');
      const fallbackPlaylist = await getFallbackPlaylist(
        this.playlist.brand,
        this.playlist.countryIso3
      );

      this.playlist.items = fallbackPlaylist.items;
      this.playlist.title = fallbackPlaylist.title;
    }

    this.trackPageView();

    if (isEmpty(this.playlist.items)) {
      log('NO PLAYLISTS WITH ITEMS');
      displayLoaderMessage('No playlists found. \n Please assign a playlist in Dealers Frontend.');
    } else {
      this.renderAsset();
    }
    this.checkForPlaylistUpdates();
  };

  trackPageView = () => {
    if (this.playlist) {
      this._paq.push([
        'trackPageView',
        `Device ID: ${localStorage.getItem('$log_deviceID')}; Playlist: ${this.playlist.title}`
      ]);
    } else {
      this._paq.push([
        'trackPageView',
        `Device ID: ${localStorage.getItem('$log_deviceID')}; Playlist: No playlist`
      ]);
    }
  };

  checkForPlaylistUpdates = (): void => {
    const updateData = async (): Promise<void> => {
      this.updatedPlaylist = await getPlaylist();

      if (
        this.playlist &&
        this.playlist.items &&
        this.updatedPlaylist &&
        this.updatedPlaylist.items &&
        !equals(this.playlist.items, this.updatedPlaylist.items)
      ) {
        log('PLAYLISTS ARE DIFFERENT, SWITCHING PLAYLIST');
        this.playlist = { ...this.updatedPlaylist };

        clearCache();
        this.resetPlayer();
      } else {
        log('PLAYLISTS ARE SAME. NO ACTION NEEDED');
      }
    };

    this.playlistUpdateInterval = setInterval(() => {
      log('HEARTBEAT: Playlist refresh');
      updateData();
    }, PLAYLIST_DEFAULTS.UPDATE_INTERVAL);
  };
  /**
   *
   * @param overrideAsset to be used for overriding
   */
  renderAsset = (overrideAsset?: IPlaylistItemSanitized): void => {
    if (!this.playlist || !this.playlist.items.length || !this.playlist.items[this.currentIndex]) {
      if (this.playlist.items.length === 0) {
        return displayLoaderMessage('Playlist is empty.');
      }
      displayLoaderMessage('No playlist to display');
      return;
    }

    const { items } = this.playlist;
    let currentItem;

    if (overrideAsset) {
      this.overrideAsset = overrideAsset;
      currentItem = overrideAsset;

      if (this.imageTimeout) {
        clearTimeout(this.imageTimeout);
      }
    } else {
      currentItem = items[this.currentIndex];
    }

    const assetType = {
      image: (): void => this.renderImage(currentItem),
      video: (): void => this.renderVideo(currentItem),
      iframe: (): void => this.renderWebContent(currentItem)
    };

    assetType[currentItem.type] && assetType[currentItem.type]();
    setLog('playerCurrentAssetID', currentItem.id);

    if (this.playerStatus !== 'active') {
      this.playerStatus = 'active';
      hideLoader();
      setLog('playerStatus', this.playerStatus);
    }
  };

  renderImage = (item: IPlaylistItemSanitized): void => {
    this.videoElement.removeEventListener('ended', this.goToNextAsset);

    this.imageElement.src = item.source;

    /**
     * Override images are displayed indefinitely.
     * Only way to escape those, is to use control skip via PATCH /digital-content/devices_DEVICE ID
     */
    if (!item.isOverride) {
      this.imageTimeout = setTimeout(() => this.goToNextAsset(), item.timer * 1000);
    }

    toggleElement(this.imageElement, true, false);
    toggleElement(this.videoElement, false, false);
  };

  renderVideo = (item: IPlaylistItemSanitized): void => {
    this.videoElement.addEventListener('ended', this.goToNextAsset);
    this.videoSource.setAttribute('src', item.source);
    this.videoElement.load();

    this.videoElement.play();

    toggleElement(this.videoElement, true, false);
    toggleElement(this.imageElement, false, false);
  };

  renderWebContent = (item: IPlaylistItemSanitized): void => {
    /**
     * iframe elements can't be initiated with empty src.
     * That is why we have to create it here.
     */
    this.videoElement.removeEventListener('ended', this.goToNextAsset);

    if (!this.iframeElement) {
      this.iframeElement = document.createElement('iframe');
      this.iframeElement.classList.add('dsp-player__iframe');
      this.iframeElement.setAttribute('width', '100%');
      this.iframeElement.setAttribute('height', '100%');
      this.playerElement.appendChild(this.iframeElement);
    }

    this.iframeElement.setAttribute('src', item.source);

    toggleElement(this.iframeElement, true, false);
    toggleElement(this.videoElement, false, false);
    toggleElement(this.imageElement, false, false);
  };

  goToNextAsset = (): void => {
    showSplash();
    if (this.overrideAsset) {
      this.resetPlayer();
      return;
    }

    if (this.currentIndex === this.playlist.items.length - 1) {
      this.currentIndex = 0;
      this.playerTimesPlayed += 1;
      setLog('playerTimesPlayed', this.playerTimesPlayed);
      this._paq.push(['deleteCustomVariable', 1, 'visit']);
      this._paq.push([
        'setCustomVariable',
        1,
        `Times played '${this.playlist.title}'`,
        this.playerTimesPlayed,
        'visit'
      ]);

      this._paq.push(['trackPageView', `Playlist "${this.playlist.title}" recursion`]);

      // this._paq.push(['trackEvent', 'Times played', this.playerTimesPlayed]);

      setStorageLog();
    } else {
      this.currentIndex = this.currentIndex + 1;
    }

    setTimeout(() => this.renderAsset(), 1000);
  };

  resetPlayer = (): void => {
    if (this.imageTimeout) {
      clearTimeout(this.imageTimeout);
    }

    this.overrideAsset = null;
    this.currentIndex = 0;

    if (this.iframeElement) {
      toggleElement(this.iframeElement, false, false);
    }
    showSplash();
    setTimeout(() => this.renderAsset(), 1000);
  };

  pause = (): void => {
    this.videoElement.pause();
  };

  play = (): void => {
    this.videoElement.play();
  };
}
