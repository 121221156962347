import axios from 'axios';
import { getToken } from '~/services/registration';

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json'
  }
});

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      // renew the token
      getToken();
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
