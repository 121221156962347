import axiosInstance from '~/axiosInstance';

import { BRANDS, ENDPOINTS, VIDEO_FORMATS } from '~/config';
import { IPlaylistSanitized } from '~/interfaces/playlists';

import { setLog } from '~/controllers/logs';
import { CURRENT_ENVIRONMENT } from '../config';
import { FallbackPlaylistSanitized } from '../interfaces/playlists';
import { log } from '../controllers/logs';
import { isEmpty } from 'ramda';
import { CollectionResponse } from '../interfaces/api';
import {
  PlaylistResource,
  ResourceResponse,
  DeviceResource,
  IPlaylistItem
} from '../interfaces/api';

/*
  Each playlist item (asset) comes from VMS with multiple sources, each with a different quality.
  Chooses the correct source based on screen resolution and asset type.
*/
const getAssetSource = (item: IPlaylistItem): string => {
  const assetType = {
    image: (): string => {
      return item.thumbnails.reference.url;
    },
    video: (): string => {
      const { innerWidth } = window;
      const { UHD, FHD, HD } = VIDEO_FORMATS;
      const currentFormat = innerWidth > 1920 ? UHD : innerWidth > 1280 ? FHD : HD;
      return item.sources[currentFormat].url;
    },
    default: null
  };

  return assetType[item.type] ? assetType[item.type]() : assetType['default'];
};

/*
  Sanitizes the playlist's items, keeping only information which is needed for the player:
    id        - unique identifier
    type      - image | video
    position  - playlist index
    source    - Akamai URL of the asset
    timer     - time to display (in seconds)
*/
const sanitizeItems = (items: Array<IPlaylistItem>): any => {
  const sanitizedItems = [];

  items.forEach(item => {
    const {
      distributedMedium: { source, type, title },
      duration,
      position,
      uuid
    } = item;

    if (source) {
      sanitizedItems.push({
        id: uuid,
        type: type,
        position: position,
        source: source,
        timer: duration,
        title: title
      });
    }
  });

  return sanitizedItems;
};

const getBrand = (kvps: string): string => {
  if (!kvps) {
    return BRANDS.AUDI;
  }

  const brandLetter = kvps.charAt(kvps.length - 1);

  switch (brandLetter) {
    case 'A':
      return BRANDS.AUDI;
    case 'C':
      return BRANDS.CUPRA;
    case 'V':
      return BRANDS.VOLKSWAGEN;
    default:
      return BRANDS.AUDI;
  }
};
/*
  Playlist's items do not come in the correct order but each one has its correct position stored.
  Compares the items based on their 'position' value and return the array sorted.
*/
const sortItems = (a: IPlaylistItem, b: IPlaylistItem): number =>
  a.position > b.position ? 1 : b.position > a.position ? -1 : 0;

const reIndex = items => {
  const nextItems = [...items];
  return nextItems.map((item, index) => ({
    ...item,
    position: index
  }));
};

const getAllItems = (playlists: PlaylistResource[]) => {
  const allItems = playlists.reduce((acc, currentValue) => acc.concat(currentValue.items), []);
  return reIndex(allItems);
};
/*
  Gets the playlist from VMS, sanitizes, sorts and returns for later usage.
*/
export const getPlaylist = async (): Promise<IPlaylistSanitized | null> => {
  log('>> GET PLAYLIST');

  try {
    const response = await axiosInstance.get<ResourceResponse<DeviceResource>>(ENDPOINTS.PLAYLIST);

    const { playlists, dealership } = response.data;

    setLog('dealership', dealership.dealershipId);
    log('>> GET PLAYLIST SUCCESS');

    return {
      items: sanitizeItems(getAllItems(playlists)),
      brand: dealership ? getBrand(dealership.kvps) : BRANDS.AUDI,
      title: playlists.map(playlist => playlist.title).join(', '),
      countryIso3: dealership.country.iso3
    };
  } catch (error) {
    log('>> GET PLAYLIST ERROR');
    console.log(error);

    return null;
  }
};

/*
  Gets the fallback playlist from VMS, sanitizes, sorts and returns for later usage.
*/
export const getFallbackPlaylist = async (
  clientName: string,
  countryIso3: string
): Promise<FallbackPlaylistSanitized> => {
  log('>> GET FALLBACK PLAYLIST');

  try {
    const response = await axiosInstance.get<CollectionResponse<PlaylistResource>>(
      ENDPOINTS.FALLBACK_PLAYLIST,
      {
        params: {
          channelSlug: CURRENT_ENVIRONMENT.CHANNEL_SLUG[clientName.toLowerCase()],
          countryIso3
        }
      }
    );

    const playlists = response.data['hydra:member'];

    log('>> GET FALLBACK PLAYLIST SUCCESS');

    if (isEmpty(playlists)) {
      return {
        items: [],
        title: ''
      };
    } else {
      const { items, title } = playlists[0];

      return {
        items: sanitizeItems(items).sort(sortItems),
        title
      };
    }
  } catch (error) {
    log('>> GET FALLBACK PLAYLIST ERROR');
    console.log(error);

    return {
      items: [],
      title: ''
    };
  }
};
