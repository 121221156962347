// declare global {
//   interface Console {
//     logs: any[];
//     stdlog: (message: any) => void;
//   }
// }

const logIdentifier = '$log_';

export const getLog = (key: string): string => {
  if (key === undefined) {
    return;
  }

  return localStorage.getItem(logIdentifier + key);
};

export const setLog = (key: string, value: string | number): void => {
  if (key === undefined || value === undefined) {
    return;
  }

  localStorage.setItem(logIdentifier + key, `${value}`);
};

export const setStorageLog = (): void => {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    navigator.storage.estimate().then(({ usage, quota }) => {
      const megabyte = 1048576;
      setLog('estimatedStorageUsed', Math.round(usage / megabyte));
      setLog('estimatedStorageAvailable', Math.round((quota - usage) / megabyte));
    });
  }
};

const filterLocalStorageItems = (): Array<string[]> => {
  return Object.entries({ ...localStorage }).filter(([key]) => key.includes(logIdentifier));
};

export const getLogs = (): { [key: string]: string } => {
  const logItems = filterLocalStorageItems();

  return Object.assign(
    {},
    ...logItems.map(([key, value]) => {
      return {
        [key.replace(logIdentifier, '')]: value
      };
    })
  );
};

export const deleteLogs = (): void => {
  const logItems = filterLocalStorageItems();

  logItems.forEach(([key]) => {
    localStorage.removeItem(key);
  });
};

export enum LogType {
  Error = 'ERROR',
  Info = 'INFO',
  Success = 'SUCCESS'
}

// console.stdlog = console.log.bind(console);
// console.logs = [];

// console.log = function() {
//   console.logs.push(Array.from(arguments));
//   console.stdlog.apply(console, arguments);
// };

// export const displayLogs = () => {};

export const log = (message: string, type: LogType = LogType.Info): void => {
  const logList = document.querySelector('.dsp-log-container__log-list');
  const logMessage = document.createElement('li');
  const content = `[${type.toUpperCase()}]:  ${message}`;
  logMessage.innerText = content;
  logList.appendChild(logMessage);
  console.log(content);
};
