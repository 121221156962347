import { DEVICE_ID } from '~/config';
import { toggleElement } from '~/utils';

const pinDialogElement = document.querySelector('.dsp-pin-pair');

export const renderPin = (pin: string): void => {
  if (!pinDialogElement || !pin) {
    return;
  }

  const pinCodeElement = pinDialogElement.querySelector('.dsp-pin-pair__code');
  pinCodeElement.innerHTML = '';

  for (let i = 0; i < pin.length; i++) {
    pinCodeElement.insertAdjacentHTML('beforeend', `<div class="dsp-pin-pair__code--box">${pin.charAt(i)}</div>`);
  }

  const pinIdentifierElement = pinDialogElement.querySelector('.dsp-pin-pair__device__id');
  pinIdentifierElement.innerHTML = DEVICE_ID;
};

export const showPin = (): void => {
  if (!pinDialogElement) {
    return;
  }

  toggleElement(pinDialogElement, true, true);
};

export const hidePin = (): void => {
  if (!pinDialogElement) {
    return;
  }

  toggleElement(pinDialogElement, false, true);
};
