import axiosInstance from '~/axiosInstance';

import { ENDPOINTS, PINNER } from '~/config';
import { setLog, log, LogType } from '../controllers/logs';

export const getPin = async (): Promise<string> => {
  log('>> PIN REQUEST');

    try {
      const response = await axiosInstance.post(ENDPOINTS.PIN, {uniqueDeviceId: localStorage.getItem('$log_deviceID')});
      log('>> PIN REQUEST SUCCESS', LogType.Success);
      return response.data.pin.toString();
    } catch (error) {
      log('>> PIN REQUEST ERROR', LogType.Error);
      throw error;
    }
};

export const getToken = async (): Promise<string> => {
  log('>> AUTHENTICATE');
  try {
    const response = await axiosInstance.post(ENDPOINTS.AUTHENTICATE, {uniqueDeviceId: localStorage.getItem('$log_deviceID')});
    const apiKey = response.data.token;
    console.log(response)
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${apiKey}`;
    log('>> AUTHENTICATE SUCCESS');
    return apiKey ? apiKey : null;
  } catch (error) {
    log('>> AUTHENTICATE ERROR');
    console.log('TOKEN ERROR: ', error);
    axiosInstance.defaults.headers['Authorization'] = `Basic ${window.btoa(
      `pinner:${PINNER}`
    )}`;
    axiosInstance.defaults.headers['Accept'] = 'application/json';
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    return null;
  }
};

export const storeClientName = (clientName: string): void => {
  setLog('clientName', clientName);
};
