import { toggleElement } from '~/utils';
import { BRANDS } from '~/config';
import { getLog } from './logs';
import { brandColors } from '../styles/colors';

const splashElement = document.querySelector('.dsp-splash') as HTMLElement;
const splashImage: HTMLImageElement = document.querySelector('.dsp-splash__image');

export const showSplash = (brand?: string): void => {
  if (!splashElement) {
    return;
  }

  const clientName = brand || getLog('clientName');

  switch (clientName) {
    case BRANDS.AUDI:
      splashImage.src = 'images/audi-logo.svg';
      break;
    case BRANDS.CUPRA:
      splashImage.src = 'images/cupra-logo.png';
      break;
    case BRANDS.VOLKSWAGEN:
      splashElement.style.backgroundColor = brandColors.volkswagen.background;
      splashImage.src = 'images/volkswagen-logo.svg';
      break;
    default:
      break;
  }
  
  toggleElement(splashElement, true, true);

setTimeout(() => toggleElement(splashElement, false, true), 1000);
};
